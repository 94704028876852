<template>
  <v-form id="register-form" ref="form" @submit.prevent="registerHandle">
    <v-container fluid>
      <v-row class="my-4">
        <v-col>
          <v-img
            :src="require(`@/assets/logo.png`)"
            max-height="20px"
            height="20px"
            contain
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="text-h5 text-center mb-4">
          {{ $trans("register_subtitle") }}
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form.email"
            :label="$trans('login_email')"
            :rules="[rules.required, rules.email]"
            color="primary"
            outlined
            prepend-icon="$account"
            validate-on-blur
            :disabled="loading"
            autocomplete="username"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="form.password"
            :append-icon="showPassword ? '$eye-on' : '$eye-off'"
            :label="$trans('password')"
            :rules="[
              rules.required,
              rules.minChars(8),
              rules.hasLowercase,
              rules.hasUppercase,
              rules.hasNumber,
              rules.hasSpecialChar,
            ]"
            :type="showPassword ? 'text' : 'password'"
            color="primary"
            outlined
            prepend-icon="$password"
            :disabled="loading"
            autocomplete="current-password"
            hide-details="auto"
            @click:append="showPassword = !showPassword"
          />
        </v-col>
        <v-col cols="12">
          <calendesk-information-message>
            {{ $trans("reset_password_text_hint") }}
          </calendesk-information-message>
        </v-col>
        <v-col cols="12">
          <p class="text-center">
            <span>{{ $trans("accept_terms_and_conditions_1") }} </span>
            <span class="link" @click="goTo('regulations')">{{
              $trans("accept_terms_and_conditions_2")
            }}</span>
            <span> {{ $trans("accept_terms_and_conditions_3") }} </span>
            <span class="link" @click="goTo('privacy_policy')">{{
              $trans("accept_terms_and_conditions_4")
            }}</span>
          </p>
        </v-col>
        <v-col v-if="error" cols="12">
          <v-alert type="error">
            {{ error }}

            <div
              v-if="emailIsTakenError"
              class="my-2 font-weight-bold underline"
              @click="goToLogin"
            >
              {{ $trans("login") }} &raquo;
            </div>
          </v-alert>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex justify-center" cols="12">
          <v-btn
            color="primary"
            form="register-form"
            min-height="56px"
            min-width="147px"
            type="submit"
            depressed
            :disabled="loading"
            :loading="loading"
          >
            <span>{{ $trans("register") }}</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="grey--text lighten-3 text-center">
          <span>{{ $trans("do_you_already_have_an_account") }} </span>
          <span class="underline" @click="goToLogin">{{
            $trans("login")
          }}</span>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import api from "@/lib/calendesk-js-library/api/api";
import {
  email,
  hasLowercase,
  hasNumber,
  hasSpecialChar,
  hasUppercase,
  maxChars,
  minChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import {
  errorNotification,
  reportError,
} from "@/lib/calendesk-js-library/tools/notification";
import { setItem } from "@/lib/calendesk-js-library/tools/localStorageManager";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "RegisterForm",
  components: {
    CalendeskInformationMessage,
  },
  data: () => ({
    showPassword: false,
    form: {
      email: null,
      password: null,
      source: null,
      source2: null,
    },
    loading: false,
    error: null,
    emailIsTakenError: false,
    rules: Object.freeze({
      required,
      email,
      minChars,
      maxChars,
      hasLowercase,
      hasUppercase,
      hasNumber,
      hasSpecialChar,
    }),
  }),
  computed: {
    ...mapGetters({
      locale: "setup/getLocale",
    }),
  },
  created() {
    if (this.$route.query.email) {
      this.form.email = this.$route.query.email;
    }

    if (this.$route.query.src) {
      this.form.source = this.$route.query.src;
    }

    const firstVisitedUrl = this.$helpers.getCookie("first_visited_url");

    if (firstVisitedUrl) {
      this.form.source2 = atob(firstVisitedUrl);
    }
  },
  methods: {
    ...mapActions({
      setTenant: "setup/setTenant",
    }),
    goToLogin() {
      this.$router.push({ name: "login" });
    },
    registerHandle() {
      if (!this.$refs.form.validate()) {
        errorNotification("form_is_invalid", null, false);
        return;
      }

      this.loading = true;
      this.error = null;
      this.emailIsTakenError = false;
      const email = this.form.email.replace(/\s/g, "").toLowerCase();

      const payload = {
        password: this.form.password,
        timezone: this.locale.timezone,
        country_iso_code: this.locale.country_iso_code,
        source: this.form.source,
        source_2: this.form.source2,
      };

      api
        .register(
          payload,
          {
            "X-Currency": this.locale.currency.toUpperCase(),
          },
          email,
        )
        .then(({ data }) => {
          this.setTenant(data.name);
          payload.token = data.token;
          this.fireEvents(payload);
          this.error = null;
          this.emailIsTakenError = false;
        })
        .catch((error) => {
          if (this.$helpers.emailIsTakenError(error)) {
            this.error = this.$trans("register_data_incorrect");
            this.emailIsTakenError = true;
          } else if (
            error &&
            error.message &&
            error.message === "EXTRA_EMAIL_VERIFICATION_FAILED"
          ) {
            this.error = this.$trans("verification_invalid_error_notification");
          } else {
            this.error = this.$trans("error_occurred");
            reportError("SIGNUP_ERROR", error);
            const reportData = this.$helpers.cloneObject(payload);
            reportData.password = "REMOVED";
            reportError("SIGNUP_ERROR_DETAILS", reportData);

            if (process.env.NODE_ENV === "production") {
              setTimeout(() => {
                location.reload();
              }, 1500);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fireEvents(payload) {
      this.$emit("registered", {
        user: {
          email: this.form.email,
          password: this.form.password,
        },
        token: payload.token,
      });

      this.$helpers.pushEvent("registrationComplete", {
        email: payload.email,
        timezone: payload.timezone,
        country_iso_code: payload.country_iso_code,
        source: this.form.source || null,
        source_2: this.form.source2 || null,
      });

      if (this.form.source) {
        setItem("source", this.form.source, false);
      }

      if (this.form.source2) {
        setItem("source_2", this.form.source2, false);
      }

      setItem("registeredUser", {
        email: payload.email,
        timezone: payload.timezone,
        country_iso_code: payload.country_iso_code,
      });
    },
    goTo(site) {
      const locale = localStorage.getItem("locale").toLowerCase();
      if (site === "regulations") {
        if (locale === "pl") {
          window.open("https://calendesk.com/pl/regulamin/", "_blank").focus();
        } else {
          window.open("https://calendesk.com/terms/", "_blank").focus();
        }
      } else if (site) {
        if (locale === "pl") {
          window
            .open("https://calendesk.com/pl/polityka-prywatnosci/", "_blank")
            .focus();
        } else {
          window
            .open("https://calendesk.com/privacy-policy/", "_blank")
            .focus();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.link {
  text-decoration: underline;
  cursor: pointer;
}
</style>
